audio {
    height: 0;
    visibility: hidden;
}

.group {
    width: 100%;
    min-height: 50px;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 20px;
    margin-top: 20px;

    .loader {
        width: 100%;
        min-height: 50px;
        margin-top: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .video {
        width: 100%;
        margin-top: 20px;
    }

    .barcode {
        display: flex;
        gap: 10px;
        width: 100%;
        padding-left: 20px;
        padding-right: 10px;

        input {
            background-color: transparent;
            border: 0;
            border-bottom: 1px solid #494949;

            &::placeholder {
                color: #494949;
            }
        }

        .ara {
            width: 60px;
            height: 40px;
            background-color: #7a166c;
            color: white;
        }

        .barcodeImage {
            width: 60px;
            height: 40px;
            background-color: #c0c0c0;
            color: white;
        }
    }

    .list {
        width: 100%;
        min-height: 60px;
        background-color: white;
        margin-top: 30px;
        border-radius: 5px;
        padding-left: 10px;
        padding-right: 10px;

        .title {
            width: 100%;
            min-height: 60px;
            background-color: white;
            display: flex;
            align-items: center;
            font-weight: 600;
            font-size: 16px;
            border-radius: 5px 5px 0 0;
            border-bottom: 1px solid #c0c0c0;

            &.noBorder {
                border-bottom: 0;
            }

            i {
                margin-left: auto;
            }
        }

        .content {
            padding-bottom: 20px;
        }

        .barcodeLength {
            width: 100%;
            font-weight: 600;
            font-size: 16px;
            margin-top: 15px;
        }

        .items {
            width: 100%;
            font-size: 15px;
        }

        .info {
            width: 100%;
            margin-top: 10px;

            .Key {
                font-size: 14px;
                font-weight: 600;
            }

            .Value {
                font-size: 14px;
            }
        }
    }

    .groupSection {
        position: relative;
        margin-top: 30px;
    }

    .groupTitle {
        min-width: 50px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-weight: 600;
        font-size: 16px;
        padding-left: 10px;
        margin-left: 10px;
        padding-right: 10px;
        background-color: #f2f2f2;
        position: absolute;
        top: -14px;
        left: 0;
        z-index: 1000000000;
    }

    .part {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 10px;
        padding-bottom: 20px;
        width: 100%;
        min-height: 100px;
        max-height: 350px;
        overflow-y: auto;
        border: 2px solid #7a166c;
        border-radius: 5px;
        position: relative;

        .list {
            &:nth-child(1) {
                margin-top: 10px !important;
            }
        }
    }
}