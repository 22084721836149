.barcodes {

    td,
    th {
        white-space: nowrap;
        font-size: 12px;

    }

    tr.new {
        animation: pulse 1s infinite;
        z-index: 1;
        background-color: green;
        color: white;

        td {
            background-color: green;
            color: white;
        }
    }

}