.loading {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.2);
    display: none;
    align-items: center;
    justify-content: center;
    z-index: 10000000;

    &.show {
        display: flex;
    }
}