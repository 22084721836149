
.box {
    width: 80%;
    min-height: 50px;
    background-color: white;
    border-radius: 5px;
    border: 1px solid #eee;
    margin-top: 30px;
    margin: 50px auto;

    .boxTitle {
        width: 100%;
        min-height: 50px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #b5b5b5;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 20px;
        padding-right: 20px;
        font-weight: 500;


    }

    .boxContent {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 30px;

        &.hide {
            display: none;
        }

        .boxItems {
            width: 90%;
            margin: 20px auto;
            border: 1px solid #eee;
            padding-bottom: 10px;

            .boxItemsTitle {
                width: 100%;
                height: 50px;
                background-color: #FF8027;
                display: flex;
                align-items: center;
                color: white;
                padding-left: 10px;
                border-radius: 5px 5px 0 0px;


            }

            .boxItem {
                padding-left: 10px;
            }
        }

        .boxItem {
            width: 90%;
            min-height: 20px;
            display: flex;
            align-items: center;
            margin-top: 20px;
            gap: 10px;

            .key {
                font-size: 13px;
                font-weight: bold;
            }

            .value {}

        }

    }
}