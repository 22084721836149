.items {
    width: 100%;
    min-height: 50px;
    margin-top: 40px;

    .item {
        width: 100%;
        min-height: 50px;
        background-color: white;
        border-radius: 5px;
        // border: 1px solid #FF8027;
        box-shadow: 0 0 8px 1px #FF8027;
        margin-top: 10px;

        .itemTitle {
            width: 100%;
            min-height: 50px;
            display: flex;
            align-items: center;
            border-bottom: 1px solid #b5b5b5;
            padding-top: 10px;
            padding-bottom: 10px;
            padding-left: 20px;
            padding-right: 20px;

            span {
                font-size: 14px;
                font-weight: bold;
            }

            .angle {
                margin-left: auto;
            }
        }

        .itemContent {
            width: 100%;
            min-height: 200px;
            padding-top: 25px;
            padding-left: 25px;
            padding-right: 25px;
            padding-bottom: 20px;

            &.hide {
                display: none;
            }

            .contentItem {
                width: 100%;
                min-height: 50px;
                display: flex;
                margin-top: 10px;
                gap: 5px;

                .key {
                    font-size: 14px;
                    font-weight: bold;
                }

                .value {
                    font-size: 16px;
                }
            }

            .button {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 20px;

                button {
                    width: 100%;
                    height: 36px;
                    background-color: #5cb85c;
                    color: white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 10px;
                }
            }

            .voyages {
                .voyageTitle {
                    width: 100%;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    border-bottom: 1px solid #bbbbbb;
                    font-size: 20px;
                    font-weight: bold;
                }

                .subItemsContent {
                    &.hide {
                        display: none;
                    }
                }



                .subItems {
                    width: 90%;
                    min-height: 10px;
                    margin: 10px auto;
                    border-bottom: 1px solid #bbbbbb;
                    padding-bottom: 20px;

                    .subItemsTitle {
                        width: 100%;
                        height: 30px;
                        display: flex;
                        align-items: center;
                        font-size: 18px;
                        font-weight: bold;

                        i {
                            margin-left: auto;
                        }

                    }

                    .subItem {
                        width: 100%;
                        min-height: 30px;
                        display: flex;
                        margin-top: 10px;
                        gap: 5px;

                        .key {
                            font-size: 14px;
                            font-weight: bold;
                        }

                        .value {
                            font-size: 16px;
                        }
                    }
                }
            }

        }
    }
}

.backDrop {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 99;
}

.actions {
    width: 100%;
    height: 280px;
    background-color: white;
    position: fixed;
    bottom: 0;
    left: 0;
    border-radius: 10px 10px 0 0;
    box-shadow: 0 -2px 2px rgba(0, 0, 0, 0.2);
    z-index: 100;

    .actionTitle {
        width: 100%;
        min-height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .actionItem {
        width: 100%;
        padding-left: 20px;
        height: 40px;
        margin-top: 10px;
        display: flex;
        align-items: center;
        gap: 5px;
        transition-duration: 0.4s;

        &:hover {
            background-color: rgba(0, 0, 0, 0.1);
        }

        img {
            width: 30px;
            height: 30px;
        }
    }
}

.modalTitle {
    width: 100%;
    font-weight: bold;
}

.modalBody {
    width: 100%;
}

.modalButtons {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    gap: 10px;

    button {
        font-size: 14px;
        font-weight: bold;
        border: 0;
        outline: none;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;

        &:hover {
            background-color: #eee;
        }
    }
}