.login {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    padding-top: 130px;
    background-color: #f2f2f2;
    padding-left: 20px;
    padding-right: 20px;
    .image {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .input {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        margin-top: 50px;
        margin: auto;

        &:first-child {
            margin-top: 50px;
        }
        margin-top: 30px;
        input {
            width: 100%;
            height: 40px;
            border: 1px solid #d3d2d2;
            background-color: transparent;
            padding-left: 10px;
            margin-top: 10px;
        }
    }

    .button {
        width: 100%;
        margin-top: 40px;
        button {
            width: 100%;
            height: 40px;
            background-color: #7a146b;
            color: white;
            border: none;
            box-shadow: none;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
            &:active,
            &:focus {
                border: none;
                box-shadow: none;
            }
        }
    }
}
