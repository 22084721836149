.barcode {
    display: flex;
    gap: 10px;
    width: 100%;
    padding-left: 20px;
    padding-right: 10px;

    input {
        background-color: transparent;
        border: 0;
        border-bottom: 1px solid #494949;

        &::placeholder {
            color: #494949;
        }
    }

    .ara {
        width: 60px;
        height: 40px;
        background-color: #7a166c;
        color: white;
    }

    .barcodeImage {
        width: 60px;
        height: 40px;
        background-color: #c0c0c0;
        color: white;
    }
}

.pageList {
    padding: 20px;

    .info {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;

        span {
            font-size: 13px;
            font-weight: 500;
        }
    }

    .barcodes {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 10px;

        .item {
            width: 45%;
            display: flex;
            flex-direction: column;
            gap: 10px;

            .key {
                font-size: 14px;
                font-weight: 500;
            }

            &:first-child {
                width: 100%;
                flex-direction: row;
                margin-bottom: 10px;

                .value {
                    font-size: 14px;
                }

            }
        }
    }
}