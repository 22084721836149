.qrCode {
    width: 100%;
    height: 300px;
    margin: auto;
    margin-top: 20px;
    padding-top: 0 !important;
    div {
        padding-top: 0 !important;
        height: 300px;
    }
}
