.filters {
    width: 100%;
    min-height: 50px;
    background-color: white;
    border-radius: 5px;
    border: 1px solid #eee;
    margin-top: 30px;

    .filterTitle {
        width: 100%;
        min-height: 50px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #b5b5b5;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 20px;
        padding-right: 20px;

        .titleLeft {
            width: 80%;
            min-height: 50px;
            display: flex;
            align-items: center;
            gap: 5px;

            span {
                font-size: 14px;
                font-weight: bold;
            }
        }

        .toggleIcon {
            margin-left: auto;
        }
    }

    .filterContent {
        width: 100%;
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 30px;

        &.hide {
            display: none;
        }

        .filterItem {
            width: 90%;
            min-height: 50px;
            display: flex;
            align-items: center;
            margin-top: 20px;

            .datePicker {
                width: 50%;
                padding-left: 10px;
                padding-right: 10px;
            }

            input {
                border: 0;
                border-bottom: 1px solid #a3a3a3;
            }
        }

        .buttons {
            width: 100%;
            min-height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 20px;
            margin-top: 20px;

            button {
                background-color: #7a166c;
                color: white;
                width: 40%;
            }
        }
    }
}