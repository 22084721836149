.header {
    @media (min-width:773px) {
        width: 600px;
    }

    width: 100%;
    height: 50px;
    background-color: #FF8027;
    display: flex;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
    color: white;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    position: relative;
    padding: 0 16px;

    .icons {
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;

        i {
            width: 40px;
            height: 50px;
            display: flex;
            align-items: center;
        }
    }

    .notifIcon{
        cursor: pointer;
    }

    .modalDialog {
        padding: 16px;
        width: 100%;
        max-width: 100%;
        color: #000;

        @media (min-width: 992px){
            width: 80%;
        }

        @media (min-width: 1200px){
            width: 70%;
        }

        @media (min-width: 1440px){
            width: 50%;
        }
    }

    .modalContent {
        width: 100%;
    }

    .modalBody {
        width: 100%;
        overflow-y: scroll;
    }

    .modalTitle {
        font-weight: bold;
    }

    .itemDescription {
        font-weight: normal;
    }

    .itemTitle {
        font-weight: bold;
    }

    .itemImage {
        width: 100%;
        margin-top: 24px;
        margin-bottom: 8px;
    }

}
