.barcode {
    display: flex;
    gap: 10px;
    width: 100%;
    padding-left: 20px;
    padding-right: 10px;

    input {
        background-color: transparent;
        border: 0;
        border-bottom: 1px solid #494949;

        &::placeholder {
            color: #494949;
        }
    }

    .ara {
        width: 60px;
        height: 40px;
        background-color: #7a166c;
        color: white;
    }

    .barcodeImage {
        width: 60px;
        height: 40px;
        background-color: #c0c0c0;
        color: white;
    }
}

.items {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-content: flex-start;
    align-items: flex-start;
    background-color: white;
    padding: 20px;
    border-radius: 5px;

    .item {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .key {
            font-size: 14px;
            font-weight: 500;
        }

    }
}