.pageTitle {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    gap: 10px;
    background-color: #7a166c;
    color: white;
}
