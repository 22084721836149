.items {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-content: flex-start;
    align-items: flex-start;
    background-color: white;
    padding: 20px;
    border-radius: 5px;

    .item {
        width: 45%;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .key {
            font-size: 14px;
            font-weight: 500;
        }

    }
}