.items {
    width: 100%;
    margin-top: 20px;
    .item {
        width: 100%;
        min-height: 50px;
        margin-top: 25px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding-left: 20px;
        padding-right: 20px;
        input {
            height: 20px;
            border: 0;
            border-bottom: 1px solid #aeaeae;
            background-color: transparent !important;
            box-shadow: none !important;
        }
        select {
            border: 0;
            border-bottom: 1px solid #aeaeae;
            background-color: transparent !important;
        }
    }

    .buttons {
        width: 100%;
        min-height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        margin-top: 30px;

        button {
            background-color: #7a166c !important;
            color: white !important;
            width: 40%;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
        }
    }
}
