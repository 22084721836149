.side {
    .info {
        background-color: var(--orange);
        width: 100%;
        height: 220px;
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding-top: 80px;
        align-items: center;
        color: white;

        span {
            font-size: 22px;

            &.branch {
                font-size: 14px;
            }
        }

        i {
            font-size: 25px;
            cursor: pointer;
        }
    }

    .items {
        .item {
            width: 100%;
            min-height: 70px;
            display: flex;
            align-items: center;
            gap: 20px;
            padding-left: 20px;
            border-bottom: 1px solid var(--orange);
            color: black;
            text-decoration: none;
            cursor: pointer;

            .text {
                font-size: 14px;
            }

            &:last-child {
                border-bottom: 0;
            }
        }
    }
}