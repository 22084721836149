audio {
    height: 0;
    visibility: hidden;
}

.button {
    width: 100%;
    min-height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;

    button {
        width: 90%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;

    }
}

.group {
    width: 100%;
    min-height: 50px;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 20px;
    margin-top: 20px;

    .loader {
        width: 100%;
        min-height: 50px;
        margin-top: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .video {
        width: 100%;
        margin-top: 20px;
    }

    .barcode {
        display: flex;
        gap: 10px;
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;

        input {
            background-color: transparent;
            border: 0;
            border-bottom: 1px solid #494949;

            &::placeholder {
                color: #494949;
            }
        }

        .ara {
            height: 40px;
            background-color: #7a166c;
            color: white;
        }

        .barcodeImage {
            width: 60px;
            height: 40px;
            background-color: #c0c0c0;
            color: white;
        }
    }
}