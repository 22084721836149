audio {
    height: 0;
    visibility: hidden;
}

.transferStatistics {
    @media (max-width:990px) {
        width: 100%;
    }

    width: 100%;
    min-height: 50px;
    border: 1px solid #7a166c;
    border-radius: 5px;
    margin-left: auto;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    background-color: white;


    .title {
        width: 100%;
        height: 50px;
        border-bottom: 1px solid #eee;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;

    }

    .items {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        row-gap: 5px;
        // column-gap: 20px;
        padding-top: 20px;

        .item {
            @media (max-width:760px) {
                width: 100%;
            }

            display: flex;
            // flex-wrap: wrap;
            align-items: flex-start;
            align-content: flex-start;
            justify-content: space-between;
            gap: 20px;

            .key {
                // width: 170px;
                font-weight: 500;
                font-size: 16px;
            }

            .progressBar {
                width: 50px;
                margin-top: 5px;
            }

            .value {
                @media (max-width:760px) {
                    margin-left: auto;
                }

                font-weight: 500;
                font-size: 16px;
                margin-right: 5px;
                color: green;
            }
        }

    }


}

.sliderBox {
    width: 100%;
    min-height: 40px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    background-color: white;
    padding: 20px;
    gap: 10px;
    margin: 0px auto;

    .item {
        display: flex;
        align-items: flex-start;
        align-content: flex-start;
        gap: 10px;
        flex-wrap: wrap;

        .key {
            font-weight: 500;
            font-size: 16px;
            white-space: nowrap;
        }

        .value {
            font-weight: 500;
            font-size: 16px;
        }
    }
}

.title {
    width: 100%;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    margin-top: 10px;

    span {
        font-size: 14px;
    }
}

.barcodePart {
    width: 100%;
    display: flex;
    flex-direction: column;

    @media (max-width:990px) {
        width: 100%;
    }




    .form {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 10px;

        .input {
            @media (max-width:990px) {
                gap: 5px;
            }

            width: 100%;
            display: flex;
            gap: 20px;
            align-items: center;

            input {
                @media (max-width:990px) {
                    font-size: 12px;
                }
            }

            .label {
                font-size: 16px;
                font-weight: 500;
            }
        }

    }

}

.video {
    width: 100%;
    margin-top: 20px;
}

.box {

    @media (max-width:990px) {
        // flex-wrap: wrap;
    }

    width: 100%;
    display: flex;
    gap: 10px;

    .items {

        width: 49%;
        margin-top: 30px;
        border: 1px solid var(--orange) !important;
        border-radius: 5px;

        .itemTitle {
            font-weight: 500;
            padding-left: 20px;
            border-bottom: 1px solid #eee;
            height: 40px;
            display: flex;
            align-items: center;
            font-size: 14px;

        }

        th {
            position: sticky;
            top: 0px;
            background: white;
        }

        .itemContent {
            width: 100%;
            height: 200px;
            overflow-y: auto;
            margin-top: 20px;
            display: flex;
            flex-direction: column;


            &::-webkit-scrollbar {
                width: 8px;
                height: 8px;
            }

            &::-webkit-scrollbar-track {
                border-radius: 8px;
                background-color: #e7e7e7;
                border: 1px solid #cacaca;
                box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 8px;
                background-color: #636363;
            }
        }



    }

}