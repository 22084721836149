.info {
    width: 95%;
    min-height: 150px;
    margin: auto;
    margin-top: 20px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2);
    padding: 10px;

    .items {
        width: 100%;
        display: flex;

        .left {
            width: 50%;
            display: flex;
            flex-direction: column;
        }

        .right {
            width: 50%;
            display: flex;
            flex-direction: column;
            border-left: 1px solid #bebebe;
            padding-left: 20px;
        }
    }

    .item {
        width: 100%;
        min-height: 30px;
        display: flex;
        align-items: center;
        gap: 5px;

        .key {
            font-weight: 500;
            font-size: 14px;
        }

        .value {
            font-size: 14px;
        }

        &.border {
            border-bottom: 1px solid #bebebe;
            margin-bottom: 10px;
        }
    }
}

.button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-top: 10px;

    button {
        width: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }
}