.items {
    width: 100%;
    margin-top: 20px;

    .item {
        width: 100%;
        min-height: 50px;
        margin-top: 25px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding-left: 20px;
        padding-right: 20px;

        .input {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;

            img {
                max-width: 90%;
                max-height: 200px;
            }




        }



    }

    .buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        margin-left: auto;
        margin-top: 15px;

        &.registerBtns {
            min-height: 50px;
            // background-color: #d6d4d4;
        }

        button {
            // background-color: #7a166c !important;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            white-space: nowrap;
        }
    }
}



//  چک باکس برای داقتیم . موقع تیک زدن زیر منو باز نشود . 