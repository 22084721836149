.camera {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 100000;

    video {
        width: 100%;
        height: 100%;
        object-fit: fill;
    }

    .capture {
        background-color: #7a166c !important;
        color: white !important;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        position: absolute;
        left: 20px;
        bottom: 30px;
    }

    img {
        width: 100%;
    }

    canvas {
        display: none;
    }

    .rotate {
        width: 100%;
        height: 50px;
        position: absolute;
        bottom: 20px;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

}