.btn {
    width: 100%;
    background-color: #7a166c;
    color: white;
    margin-top: 10px;
}

.ts {
    width: 200px;
}

.fullWidth {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100000;
    background-color: rgba(0, 0, 0, 0.5);
    padding-top: 100px;

    .btn {
        margin-top: 0 !important;
    }

}