.file {
    width: 90%;
    margin: 20px auto;
    border-radius: 0px;
    padding-top: 10px;
    padding-bottom: 10px;

    .title {
        display: flex;
        gap: 10px;
    }

    .imageList {
        width: 100%;
        min-height: 5px;
        display: flex;
        align-items: flex-start;
        align-content: flex-start;
        gap: 10px;

        .image {
            width: 60px;
            height: 60px;
            border-radius: 5px;
            box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.2);
            padding: 5px;
            position: relative;

            .progress {
                height: 5px;
            }

            i {
                position: absolute;
                top: -8px;
                right: -5px;
                cursor: pointer;
                z-index: 10000;
                background-color: white;
                border-radius: 50%;
                color: red;
            }

            img {
                width: 100%;
                height: 100%;
            }
        }
    }

    .signature {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100000;

        button {
            position: absolute;
            bottom: 10px;
            left: 50%;
            transform: translateX(-50%);
        }
    }


    .sign {
        margin-bottom: 10px;

        button {
            margin-top: 10px;
            margin-bottom: 10px;
        }

        .sigPad {
            width: 100%;
            height: 100%;
            background-color: #fff;

        }

        .signImg {
            width: 100%;
            height: 200px;
            background-color: #fff;
            margin-bottom: 10px;

            img {
                width: 100%;
                width: 100%;
                height: 200px;
            }
        }
    }


    .buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        margin-left: auto;
        margin-top: 15px;

        &.registerBtns {
            min-height: 50px;
            // background-color: #d6d4d4;
        }

        button {
            // background-color: #7a166c !important;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            white-space: nowrap;
        }
    }

}