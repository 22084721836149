.tabs {
    width: 100%;
    height: 80px;
    background-color: white;
    display: flex;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
    .tab {
        width: 100%;
        height: 80px;
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: center;
        justify-content: center;
        color: #7b7b7b;
        &.active {
            color: #791573;
            border-bottom: 4px solid #791573;
        }
    }
}
.contents {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 10px;
}
