.footer {
    @media (min-width:773px) {
        width: 600px;
        left: 50%;
        transform: translateX(-50%);
    }

    width: 100%;
    height: 55px;
    background-color: white;
    box-shadow: -2px 0 2px 2px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;

    .footerItem {
        width: 25%;
        height: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 5px;
        text-decoration: none;

        span {
            font-size: 11px;
            text-decoration: none;
            color: var(--orange);

        }



        &.active {
            transform: scale(1.1);
            background-color: #FF8027;
            color: white !important;

            path {
                fill: white !important;
            }

            span {
                color: white !important;
            }
        }

    }
}