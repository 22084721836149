@import "./styles/fontawesome2/css/all.min.css";
@import "./styles/colors.scss";

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&family=Roboto:wght@400;500;700&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // padding: 10px;
  background-color: #f2f2f2;
}

html {}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

textarea:focus,
input:focus {
  outline: none;
}

*:focus {
  outline: none;
}

* {
  box-sizing: border-box;
}

._VrCvP {
  margin-top: 5px !important;
}


.pretty-btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

  background-color: var(--orange) !important;
  color: white !important;

  &:active {
    background-color: var(--orange) !important;
  }
}


.btn-outline {
  background-color: transparent !important;
  border: 1px solid var(--orange) !important;
  color: var(--orange) !important;

  &:active {
    background-color: transparent !important;

  }
}

input,
select {
  border-bottom: 1px solid var(--orange);


}

.box-item {
  box-shadow: 0 0 5px 1px var(--orange) !important;

}

.srv-validation-message {
  color: red;
  margin-top: 5px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  text-align: left;
}

.cropper-point {
  width: 25px;
  height: 25px;
}

.page-content {
  width: 95%;
  min-height: 100px;
  margin: 10px auto;
  padding-bottom: 70px;
  padding-top: 10px;
}

audio {
  display: none;
}

.customModal {
  width: 90%;
  margin: 0 !important;
}

.text-sm {
  font-size: 12px;
  font-weight: 600;
}

.cursor-pointer {
  cursor: pointer;
}

.text-green {
  color: green;
}